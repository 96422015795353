import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { ref } from 'vue';
import { openIdGet, is_weixn } from '@/utils/wechat';
export default {
  __name: 'index',
  setup(__props) {
    const debugInfo = ref({
      openid: openIdGet(),
      is_weixn: is_weixn()
    }); // debug信息

    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", null, _toDisplayString(JSON.stringify(debugInfo.value)), 1);
    };
  }
};